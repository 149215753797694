import React, { useState, useRef, useCallback } from 'react'
import { RemoveScroll } from 'react-remove-scroll';

const Keys = {
    KEY_ARROW_DOWN: 'ArrowDown',
    KEY_ARROW_LEFT: 'ArrowLeft',
    KEY_ARROW_RIGHT: 'ArrowRight',
    KEY_ARROW_UP: 'ArrowUp',
    KEY_BACKSPACE: 'Backspace',
    KEY_ESCAPE: 'Escape',
    KEY_RETURN: 'Return',
    KEY_ENTER: 'Enter',
}

export default ({ images = [] }) => {
    const [sliderIndex, setSliderIndex] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const modalRef = useRef()

    const toRight = useCallback(() => setSliderIndex(sliderIndex + 1 > images.length - 1 ? 0 : sliderIndex + 1)
        , [sliderIndex, images])
    const toLeft = useCallback(() => setSliderIndex(sliderIndex - 1 < 0 ? images.length - 1 : sliderIndex - 1)
        , [sliderIndex, images])

    const handleKeyDown = e => {
        switch (e.key) {
            case Keys.KEY_ESCAPE:
                e.preventDefault()
                setShowModal(false)
                break
            case Keys.KEY_BACKSPACE:
                e.preventDefault()
                setShowModal(false)
                break
            case Keys.KEY_ARROW_UP:
                e.preventDefault()
                toRight()
                break
            case Keys.KEY_ARROW_DOWN:
                e.preventDefault()
                toLeft()
                break
            case Keys.KEY_ARROW_LEFT:
                e.preventDefault()
                toLeft()
                break
            case Keys.KEY_ARROW_RIGHT:
                e.preventDefault()
                toRight()
                break
            case Keys.KEY_RETURN:
                e.preventDefault()
                setShowModal(false)
                break
            default:
                break
        }
    }

    return (
        <div className="gallery__header"
            tabIndex="-1"
            onKeyDown={handleKeyDown}>
            <div className="gallery__divider">
                My favorite moments
            </div>
            <div className="gallery">
                {images.map((image, index) => (
                    <img
                        className="gallery__image"
                        alt="Collaboration"
                        src={image}
                        onClick={() => {
                            setSliderIndex(index);
                            setShowModal(true);
                        }}
                    />
                ))}
            </div>
            {showModal && (
                <RemoveScroll>
                    <div className='modal' ref={modalRef}>
                        <div className="close-button-wrapper" onClick={() => setShowModal(false)}>
                            <span className="close-button"></span>
                        </div>
                        <div className="right-button" onClick={() => toRight()}>
                            <i
                                style={{ fontSize: "25px", color: 'white' }}
                                className="icon-right-open-big"
                            ></i>
                        </div>
                        <div className="modal-content">
                            <img src={images[sliderIndex]} alt="slider image" />
                        </div>
                        <div className="left-button" onClick={() => toLeft()}>
                            <i
                                style={{ fontSize: "25px", color: 'white' }}
                                className="icon-left-open-big"
                            ></i>
                        </div>
                    </div>
                </RemoveScroll>)}
        </div>
    )

}
// class ImageGallery extends React.Component {
//     constructor(props) {
//         // fires before component is mounted
//         super(props); // makes this refer to this component
//     }

//     openModal = () => {
//         const modal = document.getElementsByClassName("modal-" + this.props.tabName)[0]
//         modal.style.display = "flex";

//         let that = this;

//         document.onkeydown = function (evt) {
//             switch (evt.keyCode) {
//                 case 37:
//                     that.plusSlides(-1);
//                     break;
//                 case 39:
//                     that.plusSlides(1);
//                     break;
//                 case 27:
//                     modal.style.display = "none";
//                     document.onkeydown = null;
//                     break;
//             }
//         };
//     }

//     closeModal = () => {
//         document.getElementsByClassName("modal-" + this.props.tabName)[0].style.display = "none";
//         document.onkeydown = null;
//     }

//     plusSlides = (n) => {
//         this.showSlides(this.slideIndex += n);
//     }

//     currentSlides = (n) => {
//         this.slideIndex = n;
//         this.showSlides(n);
//     }

//     showSlides = (n) => {
//         let i;
//         let slides = document.getElementsByClassName("mySlides-" + this.props.tabName);

//         if (n > slides.length) { this.slideIndex = 1 }
//         if (n < 1) { this.slideIndex = slides.length }
//         for (i = 0; i < slides.length; i++) {
//             slides[i].style.display = "none";
//         }
//         slides[this.slideIndex - 1].style.display = "inline-block";
//     }

//     render() {

//         return (
//             <div>
//                 <div className="row width-container image-gallery" id={"gallery" + this.props.tabName} style={{ visibility: "hidden" }}>
//                     {this.props.imagesThumb.map((el, i, arr) => {
//                         if (i == arr.length - 1) {
//                             return <div className="col-6 col-sm-6 col-md-3 px-4 thumbnail">
//                                 <div className="image-placeholder">
//                                     <img id={"imageThumb-" + this.props.tabName + "-i"} onLoad={() => { document.getElementById("gallery" + this.props.tabName).style.visibility = "visible"; document.getElementById("loading-icon" + this.props.tabName).style.display = "none"; }} onClick={() => { this.openModal(); this.currentSlides(i + 1) }} src={el} alt="" />
//                                 </div>
//                             </div>
//                         }

//                         return <div className="col-6 col-sm-6 col-md-3 px-4 thumbnail"  >
//                             <div className="image-placeholder">
//                                 <img id={"imageModal-" + this.props.tabName + "-i"} onClick={() => { this.openModal(); this.currentSlides(i + 1) }} src={el} alt="" />
//                             </div>
//                         </div>
//                     })}
//                     <div id="myModal" className={"modal" + " modal-" + this.props.tabName}>
//                         <span className="close cursor" onClick={this.closeModal}>close</span>
//                         <a className="prev text-dark mr-auto" onClick={() => this.plusSlides(-1)}>left</a>
//                         <div className="modal-content">
//                             {this.props.imagesModal.map((el, i) => {
//                                 return <div className={"mySlides " + "mySlides-" + this.props.tabName} id={"slide" + this.props.tabName.toLowerCase().replace(/\s/g, "") + "-" + i}>
//                                     <img id={"image-" + i} src={el} alt="" />
//                                 </div>
//                             })}
//                         </div>
//                         <a className="next text-dark ml-auto" onClick={() => this.plusSlides(1)}>right</a>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

// export default ImageGallery