import React from "react"
import Layout from "../components/layout"
import "../layouts/styles/index.scss"
import ImageGallery from '../components/ImageGallery'

const PartnershipPage = props => {
  const restaurantsCollabs = props.pageContext.restaurantsCollabs
  const hotelsCollabs = props.pageContext.hotelsCollabs
  const collab = props.pageContext.collab.fields

  return (
    <Layout
      isOnPartnershipPage
      restaurantsCollabs={restaurantsCollabs}
      hotelsCollabs={hotelsCollabs}
      title={collab.name}
    >
      <div className="partnership-hero">
        {collab.imageGallery && (
          <img
            key={collab.imageGallery[0].fields.file.url}
            className="collab__image collab__image--partnership"
            alt="Collaboration"
            src={"https:" + collab.imageGallery[0].fields.file.url + "?w=1100"}
          />
        )}
        <div className="row-hero row-hero--light">
          <div className="row-hero__headline">{collab.type}</div>
          <div className="row-hero__subline">{collab.name}</div>
          <div className="partnership__text">
            {collab &&
              collab.description.content.map(textElement => (
                <p
                  key={textElement.content[0].value}
                  className="partnership__text"
                >
                  {textElement.content[0].value}
                </p>
              ))}
          </div>
          <div className="partnership__icons">
            {collab.instagramLink && (
              <a href={collab.instagramLink}>
                <i style={{ fontSize: "22px" }} className="icon-instagram"></i>
              </a>
            )}
            {collab.facebookLink && (
              <a href={collab.facebookLink}>
                <i className="icon-facebook"></i>
              </a>
            )}
            {collab.websiteLink && (
              <a href={collab.websiteLink}>
                <i className="icon-desktop"></i>
              </a>
            )}
          </div>
          {collab.imageGallery &&
            <ImageGallery images={collab.imageGallery.map(collabImage => "https:" + collabImage.fields.file.url)} />
          }
        </div>
      </div>
    </Layout>
  )
}

export default PartnershipPage
